import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NavBar from "./components/NavBar";
import HomePage from "./components/HomePage";
import LoginPage from "./components/LoginPage";
import PurchaseOrdersPage from "./components/PurchaseOrdersPage";

function App() {
  return (
    <Router>
      <NavBar />
      <div style={{ marginTop: "4rem" }}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/purchaseOrders" element={<PurchaseOrdersPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
