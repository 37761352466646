/********************************************************
 * /src/components/PurchaseOrdersPage.js
 * Uses a multi-line "spreadsheet-like" table for line items,
 * along with a typical form for vendor, date, etc.
 *
 * Key Points:
 * 1) On load, fetch existing POs (GET /api/purchaseOrders).
 * 2) On create, send multiple line items as an array.
 * 3) If the token is expired, handle 401/403 gracefully.
 ********************************************************/
import React, { useState, useEffect } from "react";

function PurchaseOrdersPage() {
  const [pos, setPos] = useState([]);  // Existing POs from the DB
  const [error, setError] = useState("");
  const [vendorId, setVendorId] = useState("");
  const [poDate, setPoDate] = useState("");
  const [taxAmount, setTaxAmount] = useState("0");
  const [shippingCost, setShippingCost] = useState("0");

  // We store multiple lines in an array of objects:
  // Each line: { productId: "", quantity: "", unitCost: "", serialNumber?: "" }
  const [items, setItems] = useState([
    { productId: "", quantity: "", unitCost: "" }
  ]);

  // Backend URL (adjust as needed)
  const BACKEND_URL = "https://io.pbw.net/api/purchaseOrders";

  // 1) On load, fetch existing POs
  useEffect(() => {
    fetchPurchaseOrders();
  }, []);

  // ********** FETCH EXISTING POs **********
  async function fetchPurchaseOrders() {
    try {
      setError("");
      const token = localStorage.getItem("jwtToken");
      if (!token) {
        // Not logged in at all
        setError("Please log in first.");
        return;
      }

      const resp = await fetch(BACKEND_URL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        }
      });

      if (!resp.ok) {
        if (resp.status === 401 || resp.status === 403) {
          setError("Session expired or invalid token. Please log in again.");
        } else {
          const errData = await resp.json();
          setError(`Error fetching POs: ${errData.message}`);
        }
        return;
      }

      let data = await resp.json();
      // data might contain bigints as strings if your server code fixed it
      setPos(data);
    } catch (err) {
      console.error("Fetch POs error:", err);
      setError("Network/server error while fetching POs.");
    }
  }

  // ********** CREATE NEW PO **********
  async function handleCreatePo(e) {
    e.preventDefault();
    setError("");

    const token = localStorage.getItem("jwtToken");
    if (!token) {
      setError("Please log in before creating a PO.");
      return;
    }

    // Build the payload
    // items is an array of { productId, quantity, unitCost, serialNumber? }
    // Convert strings to numbers as needed
    const finalItems = items.map((line) => ({
      productId: parseInt(line.productId, 10),
      quantity: parseInt(line.quantity, 10),
      unitCost: parseFloat(line.unitCost),
      serialNumber: line.serialNumber || null
    }));

    const payload = {
      vendorId: parseInt(vendorId, 10),
      poDate,
      taxAmount: parseFloat(taxAmount),
      shippingCost: parseFloat(shippingCost),
      status: "open", // or read from a form field if you want
      items: finalItems
    };

    // Basic validation
    if (!payload.vendorId || !payload.poDate || !payload.items.length) {
      setError("Please fill out Vendor ID, Date, and at least one line item.");
      return;
    }

    try {
      const resp = await fetch(BACKEND_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(payload)
      });

      if (!resp.ok) {
        if (resp.status === 401 || resp.status === 403) {
          setError("Session expired or invalid token. Please log in again.");
        } else {
          const errData = await resp.json();
          setError(`Error creating PO: ${errData.message}`);
        }
        return;
      }

      const data = await resp.json();
      alert(`PO created! ID = ${data.poId}`);

      // Refresh POs
      fetchPurchaseOrders();

      // Reset form
      setVendorId("");
      setPoDate("");
      setTaxAmount("0");
      setShippingCost("0");
      setItems([{ productId: "", quantity: "", unitCost: "" }]);
    } catch (err) {
      console.error("Create PO error:", err);
      setError("Network/server error while creating PO.");
    }
  }

  // ********** HANDLE MULTIPLE LINES **********
  function handleLineChange(index, field, value) {
    const newItems = [...items];
    newItems[index][field] = value;
    setItems(newItems);
  }

  function addLine() {
    setItems([...items, { productId: "", quantity: "", unitCost: "" }]);
  }

  function removeLine(index) {
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
  }

  return (
    <div className="container mt-3">
      <h2>Purchase Orders</h2>
      {error && <div className="alert alert-danger">{error}</div>}

      {/* Existing POs Listing */}
      <div className="mb-5">
        <h4>Existing POs</h4>
        {pos.map((po) => (
          <div key={po.id} className="card mb-2">
            <div className="card-body">
              <h5>PO #{po.id}</h5>
              <p>Vendor ID: {po.vendor_id}</p>
              <p>PO Date: {po.po_date}</p>
              <p>Status: {po.status}</p>
              <p>Tax: {po.tax_amount}</p>
              <p>Shipping: {po.shipping_cost}</p>
            </div>
          </div>
        ))}
      </div>

      {/* Create New PO Form */}
      <h4>Create New PO</h4>
      <form onSubmit={handleCreatePo}>
        <div className="row mb-3">
          <div className="col-md-4">
            <label className="form-label">Vendor ID</label>
            <input
              type="number"
              className="form-control"
              value={vendorId}
              onChange={(e) => setVendorId(e.target.value)}
              required
            />
          </div>
          <div className="col-md-4">
            <label className="form-label">PO Date</label>
            <input
              type="datetime-local"
              className="form-control"
              value={poDate}
              onChange={(e) => setPoDate(e.target.value)}
              required
            />
          </div>
          <div className="col-md-2">
            <label className="form-label">Tax</label>
            <input
              type="number"
              step="0.01"
              className="form-control"
              value={taxAmount}
              onChange={(e) => setTaxAmount(e.target.value)}
            />
          </div>
          <div className="col-md-2">
            <label className="form-label">Shipping</label>
            <input
              type="number"
              step="0.01"
              className="form-control"
              value={shippingCost}
              onChange={(e) => setShippingCost(e.target.value)}
            />
          </div>
        </div>

        <h5>Line Items</h5>
        <div className="table-responsive">
          <table className="table table-bordered align-middle">
            <thead>
              <tr>
                <th>Product ID</th>
                <th>Quantity</th>
                <th>Unit Cost</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {items.map((line, index) => (
                <tr key={index}>
                  <td style={{ minWidth: "120px" }}>
                    <input
                      type="number"
                      className="form-control"
                      value={line.productId}
                      onChange={(e) =>
                        handleLineChange(index, "productId", e.target.value)
                      }
                      required
                    />
                  </td>
                  <td style={{ minWidth: "120px" }}>
                    <input
                      type="number"
                      className="form-control"
                      value={line.quantity}
                      onChange={(e) =>
                        handleLineChange(index, "quantity", e.target.value)
                      }
                      required
                    />
                  </td>
                  <td style={{ minWidth: "120px" }}>
                    <input
                      type="number"
                      step="0.01"
                      className="form-control"
                      value={line.unitCost}
                      onChange={(e) =>
                        handleLineChange(index, "unitCost", e.target.value)
                      }
                      required
                    />
                  </td>
                  <td>
                    {items.length > 1 && (
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => removeLine(index)}
                      >
                        Remove
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <button
          type="button"
          className="btn btn-secondary mb-3"
          onClick={addLine}
        >
          Add Line
        </button>

        <button type="submit" className="btn btn-primary w-100">
          Create Purchase Order
        </button>
      </form>
    </div>
  );
}

export default PurchaseOrdersPage;
