import React from "react";

function HomePage() {
  return (
    <div className="container mt-3">
      <h1>Welcome to PBW POS</h1>
      <p>This is the home page. Use the menu above to log in or manage purchase orders.</p>
    </div>
  );
}

export default HomePage;
