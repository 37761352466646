import React, { useState } from "react";

function LoginPage() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [msg, setMsg] = useState("");

  const BACKEND_URL = "https://io.pbw.net/api/auth/login";

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMsg("");

    try {
      const response = await fetch(BACKEND_URL, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ username, password })
      });
      if (!response.ok) {
        const errData = await response.json();
        setMsg(`Error: ${errData.message}`);
        return;
      }
      const data = await response.json();
      setMsg("Login successful!");
      localStorage.setItem("jwtToken", data.token);
    } catch (error) {
      console.error("Login error:", error);
      setMsg("Server or network error");
    }
  };

  return (
    <div className="container mt-3" style={{ maxWidth: "400px" }}>
      <h2>Login</h2>
      {msg && <div className="alert alert-info mt-2">{msg}</div>}

      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label className="form-label">Username</label>
          <input
            className="form-control"
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Password</label>
          <input
            className="form-control"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>

        <button className="btn btn-primary w-100" type="submit">
          Login
        </button>
      </form>
    </div>
  );
}

export default LoginPage;
